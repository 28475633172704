import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (

  
<div class="min-h-screen bg-white mb-4">
  <Header/>

  <main class="overflow-hidden">

    <div class="bg-gray-50">
      <div class="py-24 lg:py-32">
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Snow Removal</h1>
          <p class="mt-6 text-xl text-gray-500 max-w-3xl">MV Property Care is a professional snow removal company that specializes in commercial and residential snow removal in Clarion, PA, and the surrounding areas.  </p>
          <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">Our snow removal services include:</h2>
            <ul>
              <li>Snow plowing – driveways, parking lots, and drive-thrus</li>
              <li>Salting or calcium chloride</li>
              <li>Snow blowing</li>
              <li>Sidewalk snow removal</li>
            </ul>
           
        </div>
      </div>
    </div>

    

  </main>

  <Footer/>
</div>
 
    
  )
}
